.Icon {
  vertical-align: middle;
  font-size: 1rem;
  color: #282c34;
  padding: 0 0.2rem 0 0.2rem;
}
#contacts ul {
  padding: 0;
  margin: 0;
}
#contacts ul li {
  display: block;
  position: relative;
  float: left;
  padding: 0.25em;
  list-style: none inside;
}
.Alternative-link {
  color: #282c34;
  text-decoration: none;
}
.Alternative-link:hover {
  border-bottom: 1px dotted;
}
@media only screen and (max-width: 640px) {

  #contacts ul li {
    font-size: calc(10px + 1vmin);
  }
}