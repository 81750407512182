@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Montserrat:wght@400;600;900&display=swap');

.Home * {
  font-family: 'Lato', sans-serif;
  color: #282c34;
}

.Home h1, .Home h3, .Home h4 {
  font-family: 'Montserrat', sans-serif;
}

.Home ul, .Home p {
  line-height: 1.5em;
}

.Home-header {
  text-align: center;
  background-color: #82DEF3;
  min-height: 24vh;
  /* font-size: calc(10px + 2vmin); */
  padding: 0 2em 0 2em;
  display: flex;
  justify-content: center;
}
.Home-header #Title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5em;
}

#Title h1 {
  margin: 0.15em 0;
  font-size: calc(30px + 4.5vmin);
  text-transform: uppercase;
}
#Title h2 {
  display: none;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: calc(10px + 1.2vmin);
  text-transform: uppercase;
  font-weight: 100;
  letter-spacing: 0.5vmin;
  color: #ffffff;
  /* margin-bottom: 1em; */
}

.Home-header nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  line-height: 1.2em;
}
.Home-header nav li {
  display: inline;
  float: inline-start;
  /* text-align: right; */
}

.Home-header nav a {
  display: block;
  padding: 0.2em 0.5em;
  /* background-color: #dddddd; */
  text-transform: uppercase;
  font-weight: 900;
  text-decoration: none;
  font-size: 12pt;
  border-bottom: 3px solid transparent;
}
.Home-header nav a:has(svg) {
  padding: 0.135em 0.5em;
}
.Home-header nav a:hover {
  border-bottom: 3px solid #ffffff;
  background-color: #ffffff50 ;
}
.Home-header nav a.active  {
  border-bottom: 3px solid #ffffff;
}

.Home-link {
  color: #61dafb;
}

.Home-body {
  font-size: calc(10px + 1vmin);
  color: #282c34;
  padding: 0 5em 0 5em;
  height: 100%;
}

.Home-body h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: calc(1.0em + 1.2vmin);
  font-weight: 100;
  letter-spacing: 0.5vmin;
  color: #282c34;
  margin: 0;
  padding: calc(1em - 1vmin) calc(1em + 1.4vmin) 0 calc(1em + 1.4vmin);
}
.Home-body > p {
  margin: 0.5em 0;
  padding: 0 2em 0 2em;
}
.Home-body > p.subheading {
  margin: 0.5em 0 0 0;
  padding: 0 calc(1.2em + 1.4vmin) 0 calc(1.2em + 1.4vmin);
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: calc(13px + 1vmin);
}
.Home-body a {
  text-decoration: none;
  border-bottom: 1px dotted;
}
.Home-body a:hover {
  border-bottom: 1px solid;
}

.Home-body .Columns {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.Left-side {
  width: 50%;
  min-width: 30vh;
  padding: 0 2em 0 1em;
}

.Right-side {
  width: 50%;
  padding: 0 1em 0 2em;
}

.Home-footer {
  text-align: center;
  /*background-color: #82DEF3;*/
  background-image: url(./bgpx2.png);
  background-repeat: repeat-x;
  background-position: left center;
  min-height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 1vmin);
  padding: 0 2em 0 2em;
}

/* blocks */

.Block {
  /*border: 1px #dadada dashed;*/
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em 1em 0 1em;
}
.Block:first-child {
  margin-top: 0;
}

.Block h3 {
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 0.5em 0;
  /* box */
  background-color: #82DEF3;
  padding: 0.5em;
  line-height: calc(0.5em + 2vmin);
}
.Block h4 {
  margin-bottom: 0.5em;
  line-height: 1em;
  font-weight: 700;
  font-size: 0.9em;
  text-transform: uppercase;
}
.Block h4 {
  color: #535969;
}

.Block p {
  margin-top: 0.2em;
  /* text-align: justify; */
}

.Block em {
  position: relative;
}

.Block em::before {
  /* Highlight color */
  background-color: #46b96c;

  content: "";
  position: absolute;
  width: calc(100% + 4px);
  height: 60%;
  left: -2px;
  bottom: 0;
  z-index: -1;
  transform: rotate(-2deg);
}

/* adaptation */

@media only screen and (max-width: 1024px) {
  .Home-header h1 {
    line-height: 1em;
  }
  .Home-body {
    padding: 0 2em 0 2em;
  }
  .Home-body .Columns {
    display: flex;
    flex-direction: row;
  }
  .Left-side {
    width: 50%;
    /*height: 100vh;*/
    padding: 0 1em 0 1em;
  }
  .Right-side {
    width: 50%;
    padding: 0 1em 0 1em;
  }
}

@media only screen and (max-width: 786px) {
  .Home-header h1 {
    line-height: 1em;
  }
  .Home-body h2 {
    letter-spacing: 0.3vmin;
    padding: calc(1em - 1vmin) 0 0 0;
    text-align: center;
  }
  .Home-body > p {
    padding: 0 1em 0 1em;
  }
  .Home-body > p.subheading {
    margin: 0.5em 0 0 0;
    padding: 0 0.85em 0 0.85em;
    /* font-size: calc(13px + 1vmin); */
  }
  .Home-body {
    padding: 0;
  }
  .Home-body .Columns {
    display: flex;
    flex-direction: column;
  }
  .Left-side {
    padding: 0;
    width: 100%;
  }
  .Right-side {
    padding: 0;
    width: 100%;
  }
}

@media print {
  .Home-header {
    background-color: transparent;
    min-height: 15vh;
    display: flex;
    justify-content: left;
  }
  .Home-header nav {
    display: none;
  }
  .Home-header #Title {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  #Title h1 {
    line-height: 1em;
    font-size: 28pt;
  }
  #Title h2 {
    font-size: 10pt;
    display: block;
    letter-spacing: 0.1vmin;
  }
  .Home-body {
    padding: 0;
    font-size: 11pt;
  }
  .Home-body .Columns {
    display: flex;
    flex-direction: column;
  }
  .Left-side {
    padding: 0;
    width: 100%;
  }
  .Right-side {
    padding: 0;
    width: 100%;
  }
  .Home-body h2 {
    font-size: 20pt;
    /* padding: 0; */
    text-align: center;
  }
  /* .Home-body > p {
    margin: 0.5em 0;
    padding: 0 2em 0 2em;
  } */
  .Home-body > p.subheading {
    /* margin: 0.5em 0 0 0; */
    padding: 0;
    font-size: 16pt;
  }
  .Block h3 {
    background-color: transparent;
    border-left: 3pt solid;
  }
  
}