img.ProfilePicture {
    border-radius: 50%;
    width: 120px;
    /* height: 60px; */
}
div#ProfilePicture {
    width: 144px;
}

.Home-header:has(#ProfilePicture) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2%;
}

@media only screen and (max-width: 786px) {
    .Home-header:has(#ProfilePicture) {
        display: flex;
        flex-direction: column;
    }
    div#ProfilePicture {
        padding: 0.5em;
    }
}