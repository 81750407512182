.Form-group *, .Form-button button {
  font-size: calc(10px + 1vmin);
}
.Form-group {
  padding: 0.5em 1em 0 1em;
  max-width: 500px;
  display: flex;
  flex-direction: column;
}
.Form-group:has(textarea) {
  max-width: 800px;
}
.Form-group label {
  font-family: 'Montserrat', sans-serif;

  /* line-height: 1em;
  font-weight: 700;
  font-size: 0.9em;
  text-transform: uppercase; */
  min-height: fit-content;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0 0 0.5em 0;
  background-color: #82DEF3;
  padding: 0.5em;
  line-height: calc(0.5em + 2vmin);
}

.Form-group input, .Form-group textarea {
  border: 1px dotted #282c34;
  padding: 0.5em;
}
.Form-group input:focus, .Form-group textarea:focus {
  border: 1px solid #282c34;
}
.Form-button button, .Form-group input:hover, .Form-group textarea:hover {
  border: 1px solid #282c34;
}
.Form-group:not(:first-of-type) {
  margin-top: 0.5em;
}
.Form-button {
  padding-left: 1em;
  text-align: right;
  max-width: 500px;
}
.Form-button button {
  padding: 0.5em;
  background-color: #282c34;
  color: #ffffff;
  border: 1px solid #282c34;
  cursor: pointer;
}
.Form-button button:hover {
  background-color: #535969;
}
#ContactForm {
  /* width: 400px; */
  padding: 0 1em;
  margin-top: 1em;
}
#ContactForm input {
  width: calc(100% - 1em - 1vhmin);
  /* max-width: 400px; */
}
#ContactForm textarea {
  width: calc(100% - 1em - 1vhmin);
  height: 20vh;
  /* max-width: 400px; */
}
.Form-button:has(button[type="submit"]) {
  margin: 1em 0;
}

/* adaptation */

@media only screen and (max-width: 1024px) {
  #ContactForm {
    padding: 0 2em;
  }
  .Form-group {
    max-width: 500px;
    padding: 0.5em 0;
  }
  .Form-button {
    padding: 0;
  }
}

@media only screen and (max-width: 640px) {
  #ContactForm {
    padding: 0 1em;
    margin-top: 0;
  }
  .Form-group {
    padding: 1em 0;
    max-width: none;
    width: 400px;
  }
  .Form-button {
    max-width: none;
  }
}