.Block .History-item p {
  color: #8f9499;
  margin-left: 0.5em;
}
.History-item p.Normal-text, span.Normal-text {
  color: #282c34;
}

.Job-title {
  /* text-transform: uppercase; */
  /*letter-spacing: 0.05rem;*/
  font-weight: 700;
}
.Block h4 span.History-term {
  color: #8f9499;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-transform: none;
}
@media only screen and (any-hover) {
  .Block h4 span.History-term {
    display: none;
  }
  .Block .History-item:hover h4 span.History-term {
    display: initial;
  }
}

@media print {
  .Block h4 span.History-term {
    color: #707478;
    font-weight: 600;
  }
  .Block .History-item p {
    color: #282c34;
  }
  .Job-title {
    font-weight: unset;
  }
}