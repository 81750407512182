.Block h3.Outlined {
  /*border: 1px solid #97E3F1;*/
  background-color: transparent;
}
.Skill-row {
  display: flex;
  min-height: 1.5em;
}
.Skill-row .Skill-cell {
  width: 50%;
  /*line-height: 1em;*/
  vertical-align: middle;
}
.Skill-row .Level-cell {
  display: flex;
  justify-content: space-between;
  width: 6rem;
  align-items: center;
}
.Skill-row .Level-cell .Level-dot {
  background-color: #282c34;
  height: 12px;
  width: 12px;
  border-radius: 50%;
}
.Skill-row .Level-cell .active {
  background-color: #82DEF3;
}

@media print {
  .Skill-row .Skill-cell img {
    display: none;
  }
  .Skill-row .Skill-cell {
    width: 30%;
  }
  .Skill-row .Level-cell .Level-dot {
    height: 8pt;
    width: 8pt;
    border: 2px dotted;
  }
  .Skill-row .Level-cell .active {
    border: 2px solid;
  }
}